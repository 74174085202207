<template>
  <div v-if="parcel">
    for <ParcelReference :reference="parcel.reference" x-small />
    <span class="map-parcel--text">
      {{ removePadding(parcel.number) }}
    </span>
    <span> {{ parcel.street_number }} {{ parcel.street_name }} </span>
    <feature-button
      :value="getFeature(feature)"
      v-if="feature"
      class="d-inline"
      x-small
      text
      :width="60"
    />
  </div>
</template>

<script>
import { removePadding } from '../../utils/parcel';
import FeatureButton from '../Core/Features/FeatureButton.vue';
export default {
  components: {
    ParcelReference: () => import('./Reference.vue'),
    FeatureButton
  },
  props: {
    parcel: {
      type: Object,
      default: null
    },
    feature: {
      type: String,
      default: ''
    }
  },
  methods: {
    getFeature(ft) {
      ft = String(ft).toLowerCase();
      if (ft === 'energy') {
        return 'hvac';
      } else if (ft === 'taxes') {
        return 'tax';
      }
      return ft;
    },
    removePadding(args) {
      return removePadding(args);
    }
  }
};
</script>

<style>
</style>
