<template>
  <base-feature-button
    v-bind="$attrs"
    :to="linkTo"
    :width="getWidth"
    :color="buttonColor"
    :dark="isButtonDark"
  >
    <template v-slot:prefix>
      <feature-icon
        :small="iconSmall"
        v-if="!about"
        v-model="value"
        :useColor="useIconColor"
      ></feature-icon>
    </template>
    <template v-slot:text>{{ feature.text }} </template>
    <template v-slot:suffix>
      <v-icon v-if="about">mdi-information</v-icon>
    </template>
  </base-feature-button>
</template>

<script>
import BaseFeatureButton from './BaseFeatureButton.vue';
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    about: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 0
    },
    color: {
      type: String
    },
    dark: {
      type: Boolean
    },
    useIconColor: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseFeatureButton,
    FeatureIcon: () => import('@/components/Core/Features/FeatureIcon.vue')
  },
  data: () => ({
    linkTo: ''
  }),
  beforeMount() {
    if (this.about) {
      this.linkTo = this.feature.about.url;
    }
    if (this.to) {
      this.linkTo = this.to;
    }
  },
  computed: {
    iconSmall() {
      return this.$attrs['x-small'] !== undefined;
    },
    feature() {
      return this.$store.getters.featuresList.filter(e => {
        return String(e.feature || '').toLowerCase() == String(this.value || '').toLowerCase();
      })[0];
    },
    getWidth() {
      if (this.width) {
        return this.width;
      } else if (this.about) {
        return 150;
      } else {
        return 95;
      }
    },
    isButtonDark() {
      let dark = false;
      if (this.feature && this.feature.style) {
        dark = this.feature.style.dark;
      }
      return dark;
    },
    buttonColor() {
      if (this.color) {
        return this.color;
      }
      if (this.feature && this.feature.style) {
        return this.feature.style.color;
      }
      return 'primary';
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../../design/_colors.scss"
.v-btn--active
  border: 1px solid $color-peeps !important
</style>
